var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.simInfo)?_c('div',{staticClass:"makeuser px-md-5"},[_c('div',{staticClass:"container"},[(_vm.simInfo.logo && _vm.isMobile)?_c('Logo',{staticStyle:{"margin-bottom":"20px"},attrs:{"logo":_vm.simInfo.logo}}):_vm._e(),_c('div',{staticClass:"makeuser__page-title"},[_vm._v(" "+_vm._s(_vm.simInfo.makeuser_title_text ? _vm.simInfo.makeuser_title_text : "Создание персонажа")+" ")]),(_vm.simInfo.welcome_message_author_img && _vm.simInfo.welcome_message_author_name)?_c('Story',{attrs:{"name":_vm.simInfo.welcome_message_author_name,"img":_vm.$url +
        _vm.simInfo.welcome_message_author_img.toString().replace(/^http:\/\//i, 'https://'),"role":"Автор","story":_vm.simInfo.welcome_message_text}}):_vm._e(),_c('div',{staticClass:"makeuser__separate"}),_c('div',{staticClass:"makeuser__text",domProps:{"innerHTML":_vm._s(
        _vm.simInfo.makeuser_text
          ? _vm.simInfo.makeuser_text
          : 'Чтобы начать, создайте своего персонажа!'
      )}}),_c('b-row',[_c('b-col',{staticClass:"person",attrs:{"cols":"12"}},[_c('croppa',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer","margin-right":"40px","float":"left"},attrs:{"image-border-radius":30,"quality":1,"prevent-white-space":true,"canvas-color":'#ddd',"placeholder":"choose avatar","placeholder-font-size":8,"width":70,"height":70,"initial-size":"cover","title":"Click to choose"},model:{value:(_vm.myCroppa),callback:function ($$v) {_vm.myCroppa=$$v},expression:"myCroppa"}}),_c('div',{staticClass:"person__user"},[(_vm.userData.email == '')?_c('div',{staticClass:"makeuser__auth-group"},[_c('label',{staticClass:"makeuser__auth-input-label"},[_vm._v(_vm._s(_vm.simInfo.email_text ? _vm.simInfo.email_text : "Почта"))]),_c('b-form-input',{staticClass:"makeuser__input",attrs:{"placeholder":_vm.simInfo.placeholder_email_text
                  ? _vm.simInfo.placeholder_email_text
                  : 'Введите email'},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1):_vm._e(),_c('div',{staticClass:"makeuser__auth-group"},[_c('label',{staticClass:"makeuser__auth-input-label"},[_vm._v(_vm._s(_vm.simInfo.name_text ? _vm.simInfo.name_text : "Имя"))]),_c('b-form-input',{staticClass:"makeuser__input",attrs:{"placeholder":_vm.simInfo.placeholder_name_text
                  ? _vm.simInfo.placeholder_name_text
                  : 'Введите имя'},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('div',{staticClass:"makeuser__auth-group"},[_c('label',{staticClass:"makeuser__auth-input-label"},[_vm._v(_vm._s(_vm.simInfo.surname_text ? _vm.simInfo.surname_text : "Фамилия"))]),_c('b-form-input',{staticClass:"makeuser__input",attrs:{"placeholder":_vm.simInfo.placeholder_surname_text
                  ? _vm.simInfo.placeholder_surname_text
                  : 'Введите фамилию'},model:{value:(_vm.user.surname),callback:function ($$v) {_vm.$set(_vm.user, "surname", $$v)},expression:"user.surname"}})],1),_c('div',{staticClass:"makeuser__auth-group"},[_c('label',{staticClass:"makeuser__auth-input-label"},[_vm._v(_vm._s(_vm.simInfo.gender_text ? _vm.simInfo.gender_text : "Пол"))]),_c('div',{staticClass:"makeuser__ismale"},[_c('div',{class:{
                  'makeuser__ismale-button': true,
                  'makeuser__active-button': _vm.user.gender == 'М',
                  'makeuser__unactive-button': _vm.user.gender != 'М',
                },on:{"click":function($event){_vm.user.gender = 'М'}}},[_vm._v(" "+_vm._s(_vm.isMobile ? _vm.simInfo.man_small_text ? _vm.simInfo.man_small_text : _vm.man : _vm.simInfo.man_text ? _vm.simInfo.man_text : _vm.man)+" ")]),_c('div',{class:{
                  'makeuser__ismale-button': true,
                  'makeuser__active-button': _vm.user.gender == 'Ж',
                  'makeuser__unactive-button': _vm.user.gender != 'Ж',
                },on:{"click":function($event){_vm.user.gender = 'Ж'}}},[_vm._v(" "+_vm._s(_vm.isMobile ? _vm.simInfo.woman_small_text ? _vm.simInfo.woman_small_text : _vm.woman : _vm.simInfo.woman_text ? _vm.simInfo.woman_text : _vm.woman)+" ")])]),_c('div',{staticClass:"makeuser__submit",on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.simInfo.done_text ? _vm.simInfo.done_text : "Готово")+" ")])])])],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }